<script lang="ts">
    import { Icon, Trash, Banknotes } from 'svelte-hero-icons';
    export let message: any;

    export let hoveredMessageId: string | null;
    export let userId: number;

    export let onDeleteMessage: (msgId: string) => void;
    export let openPriceModal: (msg: { id: string; price: number }) => void;
</script>

<div class="message-actions-wrapper flex">
    {#if message.sender_id === userId}
        <div class="pointer-cursor mr-2 flex items-center justify-center">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <div
                class="to-tooltip message-action-button flex items-center justify-center {hoveredMessageId !==
                message.id
                    ? 'hidden'
                    : ''}"
                on:click="{() => onDeleteMessage(message.id)}"
            >
                <Icon src="{Trash}" size="{'20px'}" />
            </div>
        </div>

        {#if message.price > 0}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="mr-2 flex items-center justify-center {hoveredMessageId !== message.id ? 'hidden' : ''}">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div
                    class="to-tooltip message-action-button flex items-center justify-center"
                    on:click="{() => {
                        openPriceModal({ id: message.id, price: message.price } );
                    }}"
                >
                    <Icon src="{Banknotes}" size="{'20px'}" />
                </div>
            </div>
        {/if}
    {/if}
</div>
